import { PillColor } from 'design-system/components';
import { PLACEHOLDER } from 'design-system/data';
import { NavDataReturnData } from './types';

export const BrandStatusDisplayData = {
  Paid: {
    Color: PillColor.Green,
    Text: 'Subscription paid',
  },
  Unpaid: {
    Color: PillColor.Red,
    Text: 'Payment due',
  },
  Default: {
    Color: PillColor.Default,
    Text: PLACEHOLDER,
  },
};

export const ResponsiveSidenavData: NavDataReturnData = {
  moCRAPortalData: {
    show: false,
    base: {
      text: PLACEHOLDER,
      path: PLACEHOLDER,
      onClick: () => {},
    },
    dashboard: {
      text: PLACEHOLDER,
      onClick: () => {},
    },
    skus: {
      text: PLACEHOLDER,
      onClick: () => {},
    },
    submissions: {
      text: PLACEHOLDER,
      onClick: () => {},
    },
  },
  workbenchData: {
    show: false,
    active: false,
    text: PLACEHOLDER,
    icon: PLACEHOLDER,
    formulation: {
      text: PLACEHOLDER,
      path: PLACEHOLDER,
      onClick: () => {},
    },
    ingredientScreener: {
      show: false,
      text: PLACEHOLDER,
      path: PLACEHOLDER,
      onClick: () => {},
    },
  },
  formulatorData: {
    show: false,
    purchaseOrders: {
      show: false,
      text: PLACEHOLDER,
      icon: PLACEHOLDER,
      path: PLACEHOLDER,
      onClick: () => {},
    },
    productRequests: {
      show: false,
      text: PLACEHOLDER,
      icon: PLACEHOLDER,
      path: PLACEHOLDER,
      onClick: () => {},
    },
  },
  sephoraData: {
    show: false,
    text: PLACEHOLDER,
    path: PLACEHOLDER,
    onClick: () => {},
  },
  retailerBrandsData: {
    show: false,
    text: PLACEHOLDER,
    icon: PLACEHOLDER,
    active: false,
    metrics: {
      text: PLACEHOLDER,
      path: PLACEHOLDER,
      active: false,
      onClick: () => {},
    },
    submissions: {
      text: PLACEHOLDER,
      path: PLACEHOLDER,
      active: false,
      onClick: () => {},
    },
  },
  retailerSkusData: {
    show: false,
    text: PLACEHOLDER,
    icon: PLACEHOLDER,
    active: false,
    metrics: {
      text: PLACEHOLDER,
      path: PLACEHOLDER,
      onClick: () => {},
    },
  },
  retailerAdditionalReports: {
    show: false,
    text: PLACEHOLDER,
    icon: PLACEHOLDER,
    active: false,
    additionalReports: [],
  },
  supplierData: {
    show: false,
    listings: {
      text: PLACEHOLDER,
      icon: PLACEHOLDER,
      path: PLACEHOLDER,
      onClick: () => {},
    },
  },
  contractManufacturerData: {
    show: false,
    home: {
      text: PLACEHOLDER,
      path: PLACEHOLDER,
      icon: PLACEHOLDER,
      onClick: () => {},
    },
    sephoraBrandResults: {
      show: false,
      text: PLACEHOLDER,
      onClick: () => {},
    },
  },
  marketplaceData: {
    show: false,
    text: PLACEHOLDER,
    path: PLACEHOLDER,
    search: PLACEHOLDER,
    icon: PLACEHOLDER,
    onClick: () => {},
  },
  inviteModalData: {
    show: false,
    text: PLACEHOLDER,
    onClick: () => {},
  },
  selectedRetailerBrandId: undefined,
  homeUrls: {
    dashboard: PLACEHOLDER,
  },
  supportEmail: PLACEHOLDER,
  statusData: {
    Text: PLACEHOLDER,
    Color: PillColor.Default,
  },
  logout: () => {},
  isPathActive: () => false,
  generateHref: () => PLACEHOLDER,
  base: PLACEHOLDER,
  selectedRetailer: PLACEHOLDER,
  isInMoCRAPortal: false,
  username: PLACEHOLDER,
  email: PLACEHOLDER,
  hasUnpaidSubscriptions: false,
  displayRequestToPay: false,
  isBrand: false,
  subscriptionTiers: null,
  handleCheckout: {
    call: () => Promise.resolve(),
    loading: false,
  },
  handleBillingInfo: {
    call: () => Promise.resolve(),
    loading: false,
  },
};
