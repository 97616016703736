import { Helmet } from 'react-helmet';
import { Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { SnackbarProvider } from 'notistack';
import * as Tooltip from '@radix-ui/react-tooltip';

import history from 'utils/history';
import { AnalyticsContextProvider } from '../services/analytics/AnalyticsContext';
import { LoadingProvider } from 'context/Loading';
import { ReferralProvider } from 'context/ReferralContext';
import { UserInvitationProvider } from 'context/UserInvitationContext';
import { NoviEmailOnlyProvider } from 'context/NoviEmailOnlyContext';
import { StripeProvider } from 'context/StripeContext';
import { GlobalContextProvider } from '../context/GlobalContext';
import { AppProvider } from '../context/AppContext';
import { ModalProvider } from 'context/ModalContext';

import Routes from 'components/Routes.js';
import SentryConfiguration from 'components/SentryConfiguration';
import ChargebeeModal from 'components/ChargebeeModal';
import { PreloadedOrderContextProvider } from 'context/PreloadedOrderContext';
import { NavProvider } from 'layouts/ConnectedSidenav/context';
import { RetailerBrandPaywallProvider } from 'views/Brands/shared/RetailerBrandPaywall/context';
import TargetHubSpotWidget from './TargetHubSpotWidget';

export default function App() {
  return (
    <LoadingProvider>
      <Tooltip.Provider delayDuration={0}>
        <SnackbarProvider preventDuplicate autoHideDuration={6000}>
          <ModalProvider>
            <AppProvider>
              <GlobalContextProvider>
                <Router history={history}>
                  <TargetHubSpotWidget />
                  <SentryConfiguration>
                    <AnalyticsContextProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <NoviEmailOnlyProvider>
                          <ReferralProvider>
                            <UserInvitationProvider>
                              <RetailerBrandPaywallProvider>
                                <StripeProvider>
                                  <NavProvider>
                                    <PreloadedOrderContextProvider>
                                      <Helmet>
                                        <title>Novi</title>
                                      </Helmet>
                                      <ChargebeeModal />
                                      <Routes />
                                    </PreloadedOrderContextProvider>
                                  </NavProvider>
                                </StripeProvider>
                              </RetailerBrandPaywallProvider>
                            </UserInvitationProvider>
                          </ReferralProvider>
                        </NoviEmailOnlyProvider>
                      </MuiPickersUtilsProvider>
                    </AnalyticsContextProvider>
                  </SentryConfiguration>
                </Router>
              </GlobalContextProvider>
            </AppProvider>
          </ModalProvider>
        </SnackbarProvider>
      </Tooltip.Provider>
    </LoadingProvider>
  );
}
