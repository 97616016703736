import { Dispatch, SetStateAction, useEffect, useState, FC } from 'react';
import { Text, TextElement, TextKind } from 'design-system/components';
import { Color, PolicyRequirementType } from 'design-system/data';
import { RequirementResult } from 'design-system/types';
import { convertSnakeToCamelCase } from 'design-system/utils';

import styles from '../retailerBrandSubmissions.module.scss';
import Table from './Table';
import { useRequirementActions } from 'views/Brands/shared/RequirementActionModalContents/context';
import SlideoutModal from 'views/Brands/RetailerBrandPolicyRequirements/components/SlideoutModal';
import BrandRequirementNote from 'views/Brands/RetailerBrandEligibility/SKURequirementsPage/components/BrandRequirementNote';
import { useAttestationsFeedbackData } from './useData';
import { DocumentFeedback } from '../DocumentsFeedbackTable/types';
import {
  RequirementType,
  Requirement,
} from 'views/Brands/RetailerBrandEligibility/SKURequirementsPage/data/types';

const modalId = 'attestations-feedback-slideout-modal';

type AttestationsFeedbackTableProps = {
  retailerName?: string;
  retailerId: string;
  brandId: string;
  policyId: string;
};

interface RequirementActionsContextType {
  setModalOpen: Dispatch<SetStateAction<string | boolean>>;
  setSelectedRequirement: Dispatch<SetStateAction<Requirement>>;
  setCurrentProductId: Dispatch<SetStateAction<number | null>>;
  selectedRequirement: Requirement;
  modalRefOpen: string | boolean;
}

const AttestationsFeedbackTable: FC<AttestationsFeedbackTableProps> = ({
  retailerName,
  retailerId,
  brandId,
  policyId,
}) => {
  const [currentRow, setCurrentRow] = useState<number | null>(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [previousActive, setPreviousActive] = useState(false);
  const [nextActive, setNextActive] = useState(false);
  const endpointData = convertSnakeToCamelCase(
    useAttestationsFeedbackData(retailerId, brandId, policyId)
  );
  const currentFilteredData = endpointData?.results;

  const {
    setModalOpen,
    modalRefOpen,
    setSelectedRequirement,
    setCurrentProductId,
    selectedRequirement,
  } = useRequirementActions() as RequirementActionsContextType;

  const convertAndSetSelectedRequirement = (
    rowData: DocumentFeedback
  ): void => {
    // convert rowData to Requirement
    const convertedRequirement: Requirement = {
      name: rowData.requirementName,
      id: rowData.requirementId,
      requirementType: rowData.requirementType as RequirementType,
      retailerPolicyRequirementGroupName: rowData.pillarName,
      screeningStatus: rowData.requirementStatus,
    };
    setSelectedRequirement(convertedRequirement);
  };

  useEffect(() => {
    // selectedRequirement is un-nullable, so setting current product id to null on mount instead to prevent actions api from being called.
    // prioritizing keeping context logic more stable.
    (setCurrentProductId as Dispatch<SetStateAction<any>>)(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalNavNextLogic = (i: number): void => {
    setNextActive(i < currentFilteredData?.length - 1);
  };

  const modalNavPrevLogic = (i: number): void => {
    setPreviousActive(i > 0);
  };

  const setModalRefOpen = (isOpen: boolean) => {
    setModalOpen(isOpen ? modalId : isOpen);
  };

  const modalOpen = modalRefOpen === modalId;

  return (
    <>
      <SlideoutModal
        modalOpen={modalOpen}
        setModalOpen={setModalRefOpen}
        currentRow={currentRow}
        setCurrentRow={setCurrentRow}
        currentFilteredData={currentFilteredData}
        modalNavPrevLogic={modalNavPrevLogic}
        modalNavNextLogic={modalNavNextLogic}
        previousActive={previousActive}
        nextActive={nextActive}
        totalItemCount={currentFilteredData?.length}
        expectedRequirementType={undefined}
        hideSubmit={
          selectedRequirement?.requirementType === PolicyRequirementType.Brand
        }
        hideSubmitNote={<BrandRequirementNote />}
        isRetailer={false}
      />
      <div className={styles['section-header']}>
        <Text kind={TextKind.Display2XSMedium} element={TextElement.H2}>
          Retailer Feedback on Attestations
        </Text>
        <Text
          kind={TextKind.TextSM}
          element={TextElement.P}
          color={Color.Neutral600}
        >
          Some attestations will result in a "pending" status. When you submit
          your attestations, the retailer will either accept or reject
          attestations that were in the pending status.
        </Text>
      </div>
      <Table
        setSelectedRequirement={
          convertAndSetSelectedRequirement as Dispatch<
            SetStateAction<RequirementResult>
          >
        }
        setModalOpen={setModalRefOpen}
        setCurrentRow={setCurrentRow}
        modalNavPrevLogic={modalNavPrevLogic}
        modalNavNextLogic={modalNavNextLogic}
        currentRow={currentRow}
        modalOpen={modalOpen}
        tableData={endpointData}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
        retailerName={retailerName}
      />
    </>
  );
};

export default AttestationsFeedbackTable;
