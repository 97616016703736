export const transformFiltersToParams = (
  params: Record<string, string>
): Record<string, string> => {
  const { filters: filterJson, ...rest } = params;
  if (!filterJson) return rest;

  try {
    const filters = JSON.parse(filterJson);
    const params: Record<string, string> = rest || {};

    // Map filters to their corresponding API parameter names
    const filterToParamMap: Record<string, string> = {
      pillar: 'pillar_id',
      // Add other mappings as needed
    };

    // Transform each filter group into its corresponding API parameter
    Object.entries(filters).forEach(([key, values]) => {
      const paramKey = filterToParamMap[key];
      if (paramKey && Array.isArray(values) && values.length > 0) {
        params[paramKey] = values.join(',');
      }
    });

    return params;
  } catch (e) {
    console.warn('Error parsing filter JSON:', e);
    return {};
  }
};
