import { APIBase, APIRequestMethod, ChargebeeType } from 'design-system/types';
import fetcher from 'services/api/fetcher';
import useAsyncCall from 'services/api/useAsyncCall';
import { useApp } from 'context/AppContext';

export const useChargebeeCheckout = (retailerBrandId: number) => {
  const { setChargebeeData } = useApp();
  const asyncFn = async () => {
    const response = await fetcher(
      `/api/chargebee/retailer_consumer_brands/${retailerBrandId}/checkout_url`,
      {
        method: APIRequestMethod.GET,
        apiBase: APIBase.DJANGO,
      }
    );
    if (response.url) {
      setChargebeeData({
        url: response.url,
        exitLabel: 'Are you sure you want to exit?',
        exitDescription: 'Your progress will not be saved.',
        type: ChargebeeType.CHECKOUT,
      });
    }
  };
  return useAsyncCall(asyncFn as unknown as () => null);
};

export const useChargebeeBillingInfo = (retailerBrandId: number) => {
  const { setChargebeeData } = useApp();
  const asyncFn = async () => {
    const response = await fetcher(
      `/api/chargebee/retailer_consumer_brands/${retailerBrandId}/self_serve_url`,
      {
        method: APIRequestMethod.GET,
        apiBase: APIBase.DJANGO,
      }
    );
    if (response.url) {
      setChargebeeData({
        url: response.url,
        exitLabel: 'Are you sure you want to exit?',
        exitDescription:
          'Please make sure any changes are saved before exiting.',
        type: ChargebeeType.BILLINGINFO,
      });
    }
  };
  return useAsyncCall(asyncFn as unknown as () => null);
};
