import {
  Button,
  ButtonIconPosition,
  ButtonKind,
  ButtonSize,
  Pill,
  PillColor,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { BrandPolicySubmissionStatus, Color } from 'design-system/data';
import { ReviewType } from '../../types';
import styles from './review-brand.module.scss';

interface ActionButtonsProps {
  submissionStatus: keyof typeof BrandPolicySubmissionStatus;
  policySubmissionId: number;
  setReviewType: (reviewType: ReviewType) => void;
  handleReviewAction: (policySubmissionId: number) => void;
  className?: string;
}

const ActionButtons = ({
  className,
  submissionStatus,
  policySubmissionId,
  setReviewType,
  handleReviewAction,
}: ActionButtonsProps) => {
  return (
    <div className={className}>
      {(submissionStatus === BrandPolicySubmissionStatus.Pending ||
        submissionStatus === BrandPolicySubmissionStatus.Ready) && (
        <div className={styles['action-buttons']}>
          <Button
            kind={ButtonKind.Error}
            size={ButtonSize.Small}
            usePhosphorIcon
            iconName="X"
            iconSize={20}
            iconColor={Color.Red700}
            iconPosition={ButtonIconPosition.Right}
            onClick={() => {
              handleReviewAction(policySubmissionId);
              setReviewType(ReviewType.Reject);
            }}
          >
            Reject brand
          </Button>
          <Button
            disabled={submissionStatus === BrandPolicySubmissionStatus.Pending}
            kind={ButtonKind.Primary}
            size={ButtonSize.Small}
            usePhosphorIcon
            iconName="Check"
            iconSize={20}
            iconPosition={ButtonIconPosition.Right}
            onClick={() => {
              handleReviewAction(policySubmissionId);
              setReviewType(ReviewType.Approve);
            }}
          >
            Accept brand
          </Button>
        </div>
      )}
      {submissionStatus === BrandPolicySubmissionStatus.Approved && (
        <Pill color={PillColor.Green}>
          <Text kind={TextKind.TextSMBold} element={TextElement.Span}>
            Accepted
          </Text>
        </Pill>
      )}
      {submissionStatus === BrandPolicySubmissionStatus.Rejected && (
        <Pill color={PillColor.Red}>
          <Text kind={TextKind.TextSMBold} element={TextElement.Span}>
            Rejected
          </Text>
        </Pill>
      )}
    </div>
  );
};

export default ActionButtons;
