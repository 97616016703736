import { useState, FC } from 'react';
import cn from 'classnames';

import {
  InputV2,
  FilterDropdown,
  DropdownRadioMenu,
  Text,
  TextElement,
  TextKind,
  PhosphorIcon,
} from 'design-system/components';
import {
  useStatefulQueryParam,
  useNameSpacedQueryParam,
} from 'design-system/utils';
import { UrlParam, Color } from 'design-system/data';
import { useFilterStates } from './useFilterStates';

import styles from './search-filter.module.scss';
import { AccordionOptionGroup } from '../filter-dropdown';

export type SearchFilterProps = {
  filterOptions?: AccordionOptionGroup[] | null;
  sortOptions?: Record<string, string>;
  useNameSpacedParams?: boolean;
  namespace?: string;
};

const SearchFilter: FC<SearchFilterProps> = ({
  filterOptions,
  sortOptions,
  namespace = '',
}: SearchFilterProps) => {
  const nameSpacedParamsEnabled = Boolean(namespace);
  const params = new URLSearchParams();

  // Search param handling
  const [search, setSearch] = useStatefulQueryParam(
    UrlParam.Search,
    params.get(UrlParam.Search) || '',
    !nameSpacedParamsEnabled
  );
  const [nameSpacedSearch, setNameSpacedSearch] = useNameSpacedQueryParam(
    UrlParam.Search,
    namespace,
    params.get(UrlParam.Search) || '',
    nameSpacedParamsEnabled
  );

  // Sort param handling
  const [activeSort, setActiveSort] = useStatefulQueryParam(
    UrlParam.Direction,
    params.get(UrlParam.Direction) || '',
    !nameSpacedParamsEnabled
  );
  const [nameSpacedSort, setNameSpacedSort] = useNameSpacedQueryParam(
    UrlParam.Direction,
    namespace,
    params.get(UrlParam.Direction) || '',
    nameSpacedParamsEnabled
  );

  // Filter states handling
  const filterParams = useFilterStates(
    filterOptions,
    namespace,
    nameSpacedParamsEnabled
  );

  const [error] = useState<string>('');

  const handleSearch = (e: any) => {
    e.preventDefault();
    nameSpacedParamsEnabled
      ? setNameSpacedSearch(e.target.value)
      : setSearch(e.target.value);
  };

  const handleActiveSort = (value: string) => {
    nameSpacedParamsEnabled ? setNameSpacedSort(value) : setActiveSort(value);
  };

  const handleFilterChange = (groupTitle: string, values: string[]) => {
    const group = filterParams[groupTitle];
    if (group) {
      group.setValue(values);
    }
  };

  // Get all selected values
  const getAllSelectedValues = () => {
    if (!filterOptions) return [];
    return filterOptions.reduce((acc, group) => {
      const values = filterParams[group.title]?.value || [];
      return [...acc, ...values];
    }, [] as string[]);
  };

  const computedSearch = nameSpacedParamsEnabled ? nameSpacedSearch : search;
  const computedSort = nameSpacedParamsEnabled ? nameSpacedSort : activeSort;

  return (
    <div role="search" className={cn(styles['search-filter'])}>
      <InputV2
        type="text"
        name="search"
        value={computedSearch}
        placeholder="Search"
        handleInputChange={handleSearch}
        errorMessage={error}
        hasError={error.length > 0}
      />
      <div className={cn(styles['drop-down-menus'])}>
        {filterOptions && (
          <div>
            <FilterDropdown
              accordionOptions={filterOptions}
              selectedValues={getAllSelectedValues()}
              setSelectedValues={(values) => {
                // Find which group was changed by comparing the new values with current values
                if (!filterOptions) return;

                filterOptions.forEach((group) => {
                  const currentValues = filterParams[group.title]?.value || [];
                  const newGroupValues = values.filter((value) =>
                    group.options.some((option) => option.value === value)
                  );

                  if (
                    JSON.stringify(currentValues) !==
                    JSON.stringify(newGroupValues)
                  ) {
                    handleFilterChange(group.title, newGroupValues);
                  }
                });
              }}
            />
          </div>
        )}
        {sortOptions && (
          <div>
            <DropdownRadioMenu
              fromLeft={true}
              options={sortOptions}
              reverse
              activeValue={computedSort}
              setActiveValue={handleActiveSort}
              triggerElement={
                <div className={styles['trigger-container']}>
                  <PhosphorIcon
                    iconName="ArrowsDownUp"
                    title="sort-by"
                    size={20}
                  />
                  <Text
                    kind={TextKind.TextSMSemibold}
                    element={TextElement.Span}
                    color={Color.Neutral600}
                    className={styles.nowrap}
                  >
                    {computedSort && sortOptions
                      ? `Sort by: ${sortOptions[computedSort]}`
                      : 'Sort by'}
                  </Text>
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchFilter;
