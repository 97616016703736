import { lazy } from 'react';
import { Switch } from 'react-router-dom';
import View from 'components/View.js';
import { useApp } from 'context/AppContext';
import { PATHS, ROLES } from 'constants/index';
import NoNav from 'layouts/NoNav';
import NoviNav, { BaseNav } from 'layouts/NoviNav';
import Home from 'views/Home';
import NotFound from 'views/NotFound';
import ConnectedSidenav from 'layouts/ConnectedSidenav';

import SupplierListings from 'views/Supplier/Listings';
import SupplierMaterial from 'views/Supplier/Material';
import NoSideBarNav from 'layouts/NoSideBarNav';
import BrandDocumentsView from 'views/Brands/Documents';
import YourProductsView from 'views/Brands/YourProducts';
import CreateProductView from 'views/Brands/CreateProduct';
import { UserPermissions } from 'constants/permissions.js';
import PortalSelection from 'views/Brands/PortalSelection';
import RetailerBrandWelcome from 'views/Brands/RetailerBrandWelcome';
import RetailerBrandDashboard from 'views/Brands/RetailerBrandDashboard';
import RetailerBrandEligibility from 'views/Brands/RetailerBrandEligibility';
import RetailerBrandSubmissions from 'views/Brands/RetailerBrandSubmissions';
import RetailerBrandSubmissionsV2 from 'views/Brands/RetailerBrandSubmissionsV2';
import RetailerBrandRequirementGroups from 'views/Brands/RetailerBrandRequirementGroups';
import RetailerBrandPolicyRequirements from 'views/Brands/RetailerBrandPolicyRequirements';
import RetailerBrandRequirementGroupsDetail from 'views/Brands/RetailerBrandRequirementGroupsDetail';
import RetailerBrandRequirementSummary from 'views/Brands/RetailerBrandRequirementSummary';
import RetailerViewBrandSubmissions from 'views/Retailer/Submissions';
import RetailerSkuMetrics from 'views/Retailer/Products/Metrics';
import RetailerBrandMetrics from 'views/Retailer/Brands/Metrics';
import RetailerDashboardView from 'views/Retailer/Dashboard';
import RetailerReportsView from 'views/Retailer/Reports';
import RetailerAdditionalReports from 'views/Retailer/AdditionalReports';
import FDARegistrationDashboard from 'views/FDARegistration/Dashboard';
import FDARegistrationProducts from 'views/FDARegistration/Products';
import FDARegistrationProductsDetail from 'views/FDARegistration/Products/Detail';
import FDARegistrationBusiness from 'views/FDARegistration/Business';
import FDARegistrationSubmissions from 'views/FDARegistration/Submissions';
import FDARegistrationWelcome from 'views/FDARegistration/Welcome';
import { useRetailerBrandPaywall } from 'views/Brands/shared/RetailerBrandPaywall/context';

const IngredientScreening = lazy(() => import('views/IngredientScreening'));
const Marketplace = lazy(() => import('views/Marketplace'));
const TermsOfService = lazy(() => import('views/TermsOfService'));

const ConsumerProducts = lazy(() => import('views/ConsumerProducts'));
const ConsumerProductDetails = lazy(() =>
  import('views/ConsumerProducts/ProductDetails')
);
const Projects = lazy(() => import('views/Projects'));
const UserSettings = lazy(() => import('views/Settings/UserSettings'));
const BrandProfileSettings = lazy(() =>
  import('views/Settings/BrandProfileSettings')
);
const Signin = lazy(() => import('views/SignIn'));
const SignOut = lazy(() => import('views/SignOut'));
const LoginSignup = lazy(() => import('views/LoginSignup'));
const SignUp = lazy(() => import('views/SignUp'));
const MoCRASignup = lazy(() => import('views/SignUp/MoCRASignup'));
const PoliciesSelect = lazy(() => import('views/PoliciesExperiment/Select'));
const PoliciesCheckoutStatus = lazy(() =>
  import('views/PoliciesExperiment/CheckoutStatus')
);
const PurchaseOrders = lazy(() => import('views/PurchaseOrders'));
const ProductRequests = lazy(() => import('views/ProductRequests'));
// Retailer Views
const RetailerBrandDetailsView = lazy(() =>
  import('views/Retailer/BrandDetails')
);
const RetailerProductsView = lazy(() => import('views/Retailer/Products'));
const RetailerProductDetailsView = lazy(() =>
  import('views/Retailer/ProductDetails')
);
const RetailerDocumentsView = lazy(() => import('views/Retailer/Documents'));

// Brand views
const BrandProductView = lazy(() => import('views/Brands/Product'));

// Sephora nonsense
const SephoraBrandConditionsView = lazy(() =>
  import('views/Sephora/SephoraConditions/BrandView')
);
const SephoraConditionsView = lazy(() =>
  import('views/Sephora/SephoraConditions/SephoraView')
);
const SephoraRedirect = lazy(() => import('views/Sephora/SephoraRedirect'));
const SephoraBrands = lazy(() => import('views/Sephora/SephoraBrands'));
const SephoraGreyIngredients = lazy(() =>
  import('views/Sephora/SephoraGreyIngredients')
);
const SephoraBannedList = lazy(() => import('views/Sephora/SephoraBannedList'));
const SephoraDashboard = lazy(() => import('views/Sephora/SephoraDashboard'));

export default function Routes() {
  const { user, retailerBrand } = useApp();
  const isBrand = user?.hasRole(ROLES.brand);
  const isFormulator = user?.hasRole(ROLES.formulator);
  const isRetailer = user?.hasRole(ROLES.retailer);
  const isSupplier = user?.hasRole(ROLES.supplier);
  const brandIsInRetailerV5 = retailerBrand?.is_vetting_v5;
  const hasSubmissionV2 =
    user?.hasFF('tmp_brand_submission_tabs') && brandIsInRetailerV5;
  const { cannotReviewRequirements } = useRetailerBrandPaywall();

  const determineSidebarType = () => {
    if (isBrand || isSupplier || isRetailer || isFormulator) {
      return ConnectedSidenav;
    }
    return NoviNav;
  };

  return (
    <Switch>
      {/* common routes */}
      <View
        Component={Home}
        Layout={determineSidebarType()}
        path={PATHS.base}
        title="Home"
        secure={true}
        exact={true}
      />
      <View
        Component={Signin}
        Layout={NoNav}
        path={PATHS.signIn}
        secure={false}
        title={'Sign In'}
        exact={true}
      />
      <View
        Component={LoginSignup}
        Layout={NoNav}
        path={PATHS.loginSignup}
        secure={false}
        title={'Access Novi'}
        exact={true}
      />
      <View
        Component={SignOut}
        Layout={NoNav}
        path={PATHS.signOut}
        secure={false}
        title={'Sign Out'}
        exact={true}
      />
      <View
        Component={TermsOfService}
        Layout={NoNav}
        path={PATHS.tos}
        secure={true}
        requiresTos={false}
        title={'Policy Privacy'}
        exact={true}
      />
      <View
        Component={UserSettings}
        Layout={determineSidebarType()}
        path={PATHS.userSettings}
        secure
        title={'User Settings'}
        exact
        roles={[
          ROLES.brand,
          ROLES.contractManufacturer,
          ROLES.retailer,
          ROLES.supplier,
          ROLES.formulator,
        ]}
      />
      <View
        Component={BrandProfileSettings}
        Layout={determineSidebarType()}
        path={PATHS.brandProfileSettings}
        secure
        title={'Brand Profile Settings'}
        exact
        roles={[ROLES.brand]}
      />
      {/* brand routes */}
      <View
        Component={PoliciesCheckoutStatus}
        Layout={determineSidebarType()}
        title={'Checkout Confirmation'}
        path="/policies/checkout/success"
        secure={true}
        exact={true}
      />
      <View
        Component={PoliciesSelect}
        Layout={determineSidebarType()}
        path="/policies/select"
        title="Policy Selection"
        secure={true}
        exact={true}
      />
      <View
        Component={ConsumerProducts}
        Layout={determineSidebarType()}
        path={[PATHS.projectDashboard]}
        secure={true}
        title={'Products'}
        exact={true}
        roles={[ROLES.brand, ROLES.contractManufacturer, ROLES.formulator]}
      />
      <View
        Component={ConsumerProductDetails}
        Layout={determineSidebarType()}
        path={[
          `${PATHS.projectDashboard}/:productId`,
          `${PATHS.projectDashboard}/:productId/actions`,
        ]}
        secure={true}
        title={'Products'}
        exact={true}
        roles={[ROLES.brand, ROLES.contractManufacturer]}
      />
      <View
        Component={Projects}
        Layout={determineSidebarType()}
        path={[
          PATHS.formulatorDashboard,
          '/projects/:projectId/overview',
          '/projects/:projectId/select',
          '/projects/:projectId/packaging',
          '/projects/:projectId/manufacturer',
          '/projects/:projectId/testing',
          '/projects/:projectId/certification',
          '/projects/:projectId/formulations',
          '/projects/:projectId/quotes',
          '/projects/:projectId/quotes/:quoteId',
          '/projects/:projectId/formulation/:formulationId',
          '/projects/:projectId/formulation/:formulationId/actions',
        ]}
        secure={true}
        title={'Projects'}
        exact={true}
        roles={[ROLES.brand, ROLES.formulator]}
      />
      <View
        Component={IngredientScreening}
        Layout={determineSidebarType()}
        path={PATHS.ingredient_search}
        secure={true}
        title={'Ingredient Screening'}
        exact={true}
        roles={[ROLES.brand, ROLES.contractManufacturer, ROLES.formulator]}
      />
      <View
        Component={() => <IngredientScreening publicPage />}
        Layout={NoNav}
        path={PATHS.public_ingredient_search}
        secure={false}
        title={'Ingredient Screening'}
        exact={true}
      />
      <View
        Component={Marketplace}
        Layout={determineSidebarType()}
        title={'Discover'}
        path={[PATHS.product_marketplace, PATHS.product_marketplace_detail]}
        secure
        exact
      />

      {/* BRAND RETAILER ROUTES */}
      {/* TO-DO: Remove old-style (withId) paths once we're okay breaking any saved links */}
      <View
        featureFlags={{
          flags: ['retailer_brand_onboarding'],
          options: { every: false },
        }}
        Component={PortalSelection}
        Layout={BaseNav}
        title="Portal Selection"
        path={[PATHS.portalSelection]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerBrandWelcome}
        Layout={NoNav}
        title="Retailer Brand Dashboard"
        path={[PATHS.retailerBrandWelcome, PATHS.retailerBrandWelcomeWithId]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerBrandDashboard}
        Layout={ConnectedSidenav}
        title="Retailer Brand Dashboard"
        path={[PATHS.retailerBrandDash, PATHS.retailerBrandDashWithId]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerBrandRequirementGroups}
        Layout={ConnectedSidenav}
        title="Retailer Brand Pillars Overview"
        path={[
          PATHS.retailerBrandRequirementGroups,
          PATHS.retailerBrandRequirementGroupsWithId,
        ]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerBrandRequirementGroupsDetail}
        Layout={ConnectedSidenav}
        title="Retailer Brand PillarsCategory"
        path={[
          PATHS.retailerBrandRequirementGroupsDetail,
          PATHS.retailerBrandRequirementGroupsDetailWithId,
        ]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerBrandPolicyRequirements}
        Layout={ConnectedSidenav}
        title="Retailer Brand Pillar Requirements"
        path={[
          PATHS.retailerBrandRequirementPolicies,
          PATHS.retailerBrandRequirementPoliciesWithId,
        ]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      {!cannotReviewRequirements && (
        <View
          featureFlags={['retailer_brand_onboarding']}
          Component={RetailerBrandRequirementSummary}
          Layout={ConnectedSidenav}
          title="Retailer Brand Pillar Requirement General Attestation"
          path={[
            PATHS.retailerBrandRequirementSummary,
            PATHS.retailerBrandRequirementSummaryWithId,
          ]}
          roles={[ROLES.brand]}
          secure
          exact
        />
      )}
      <View
        Component={RetailerBrandEligibility}
        Layout={ConnectedSidenav}
        title="Eligibility"
        path={[
          PATHS.retailerBrandEligibility,
          PATHS.retailerBrandSKULevelStatus,
        ].concat(
          !cannotReviewRequirements ? [PATHS.retailerBrandSKURequirements] : []
        )}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerDashboardView}
        Layout={ConnectedSidenav}
        title="Retailer Dashboard"
        path={[PATHS.retailerDashboard]}
        roles={[ROLES.retailer]}
        secure
      />
      <View
        featureFlags={['sephora_reports_page']}
        Component={RetailerReportsView}
        Layout={ConnectedSidenav}
        title="Retailer Reports"
        path={[PATHS.retailerReports]}
        roles={[ROLES.retailer]}
        secure
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerBrandMetrics}
        Layout={ConnectedSidenav}
        title="Retailer Brand Metrics"
        path={[PATHS.retailerBrandsMetrics]}
        roles={[ROLES.retailer]}
        secure
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerViewBrandSubmissions}
        Layout={ConnectedSidenav}
        title="Retailer Brand Submission Container"
        path={[PATHS.retailerBrandsSubmissions]}
        roles={[ROLES.retailer]}
        secure
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerSkuMetrics}
        Layout={ConnectedSidenav}
        title="Retailer SKU Metrics"
        path={[PATHS.retailerSkuMetrics]}
        roles={[ROLES.retailer]}
        secure
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerAdditionalReports}
        Layout={ConnectedSidenav}
        title="Retailer Additional Report Detail"
        path={[PATHS.retailerAdditionalReportsDetail]}
        roles={[ROLES.retailer]}
        secure
      />
      <View
        requiredPermissions={[UserPermissions.CanAccessRetailerBrandExperience]}
        Component={BrandDocumentsView}
        Layout={ConnectedSidenav}
        title="Your Documents"
        path={[
          PATHS.retailerBrandDocuments,
          PATHS.retailerBrandDocumentsWithId,
        ]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        requiredPermissions={[UserPermissions.CanAccessRetailerBrandExperience]}
        Component={BrandProductView}
        Layout={NoNav}
        title="Product View"
        path={[PATHS.brandProductView]}
        roles={[ROLES.brand]}
        secure
      />
      <View
        requiredPermissions={[UserPermissions.CanAccessRetailerBrandExperience]}
        Component={BrandDocumentsView}
        Layout={ConnectedSidenav}
        title="Your Documents"
        path={[PATHS.brandDocumentsView]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        requiredPermissions={[UserPermissions.CanAccessRetailerBrandExperience]}
        Component={YourProductsView}
        Layout={ConnectedSidenav}
        title="Your Products"
        path={[PATHS.brandYourProductsView]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        requiredPermissions={[UserPermissions.CanAccessRetailerBrandExperience]}
        Component={YourProductsView}
        Layout={ConnectedSidenav}
        title="Your Products"
        path={[PATHS.retailerBrandProducts, PATHS.retailerBrandProductsWithId]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        requiredPermissions={[UserPermissions.CanAccessRetailerBrandExperience]}
        Component={CreateProductView}
        Layout={ConnectedSidenav}
        title="Upload Your Product"
        path={[PATHS.retailerBrandProductCreate]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        requiredPermissions={[UserPermissions.CanAccessRetailerBrandExperience]}
        Component={
          hasSubmissionV2
            ? RetailerBrandSubmissionsV2
            : RetailerBrandSubmissions
        }
        Layout={ConnectedSidenav}
        title="Submissions"
        path={[
          PATHS.retailerBrandSubmissions,
          ...(hasSubmissionV2 ? [PATHS.retailerBrandSubmissionsWithId] : []),
        ]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        Component={SephoraBrandConditionsView}
        Layout={determineSidebarType()}
        title="Brand Conditions"
        path={[PATHS.brandConditions]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        Component={SephoraConditionsView}
        Layout={determineSidebarType()}
        title="Brand Conditions"
        path={[PATHS.sephoraConditions]}
        roles={[ROLES.contractManufacturer]}
        secure
        exact
      />
      <View
        Component={SephoraRedirect}
        Layout={determineSidebarType()}
        title="Activate Sephora"
        path={'/brand/activate-sephora-planet-delta'}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        Component={SephoraBrands}
        Layout={determineSidebarType()}
        title="Brand Results"
        path={'/sephora/brands'}
        roles={[ROLES.contractManufacturer]}
        secure
        exact
      />
      <View
        Component={SephoraGreyIngredients}
        Layout={determineSidebarType()}
        title="Grey Ingredients"
        path={PATHS.sephoraGreyIngredients}
        roles={[ROLES.brand, ROLES.contractManufacturer]}
        secure
        exact
      />
      <View
        Component={SephoraBannedList}
        Layout={determineSidebarType()}
        title="Banned List"
        path={PATHS.sephoraBannedList}
        roles={[ROLES.brand, ROLES.contractManufacturer]}
        secure
        exact
      />
      <View
        Component={SephoraDashboard}
        Layout={determineSidebarType()}
        title="Sephora Planet Delta"
        path={PATHS.sephoraBrandDashboard}
        roles={[ROLES.brand]}
        secure
        exact
      />
      {/* supplier 2.0 routes  */}
      <View
        Component={SupplierListings}
        Layout={ConnectedSidenav}
        path={PATHS.supplierListings}
        roles={[ROLES.supplier]}
        secure
        exact
      />
      <View
        Component={SupplierMaterial}
        Layout={NoSideBarNav}
        path={[PATHS.supplierMaterialId, PATHS.supplierMaterialPolicies]}
        roles={[ROLES.supplier]}
        secure
        exact
      />
      {/* FDA Registration Routes */}
      <View
        Component={FDARegistrationDashboard}
        Layout={determineSidebarType()}
        title="FDA Registration Dashboard"
        path={[PATHS.fdaRegistrationDashboard]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        Component={FDARegistrationWelcome}
        Layout={NoNav}
        title="FDA Registration Welcome"
        path={[PATHS.fdaRegistrationWelcome]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        Component={FDARegistrationProducts}
        Layout={determineSidebarType()}
        title="FDA Product Registration"
        path={[
          PATHS.fdaRegistrationProduct,
          PATHS.fdaRegistrationSubmissionConfirmation,
        ]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        Component={FDARegistrationProductsDetail}
        Layout={determineSidebarType()}
        title="FDA Product Registration - SKU Details"
        path={[PATHS.fdaRegistrationProductDetail]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        Component={FDARegistrationBusiness}
        Layout={determineSidebarType()}
        title="FDA Business Registration"
        path={[PATHS.fdaRegistrationBusiness]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      <View
        Component={FDARegistrationSubmissions}
        Layout={determineSidebarType()}
        title="FDA Submissions"
        path={[
          PATHS.fdaRegistrationSubmissions,
          PATHS.fdaRegistrationSubmissionDetail,
        ]}
        roles={[ROLES.brand]}
        secure
        exact
      />
      {/* MISC */}
      <View
        Component={SignUp}
        Layout={NoNav}
        exact={true}
        path={[PATHS.signUp]}
        secure={false}
        title="Novi Signup"
      />
      <View
        Component={MoCRASignup}
        Layout={NoNav}
        exact={true}
        path={[PATHS.mocraSignup]}
        secure={false}
        title="Novi <> MoCRA Signup"
      />
      <View
        Component={PurchaseOrders}
        Layout={ConnectedSidenav}
        exact={true}
        path={[PATHS.purchaseOrders]}
        roles={[ROLES.formulator]}
        secure={true}
        title="Purchase Orders"
      />
      <View
        featureFlags={['product_requests_tracker']}
        Component={ProductRequests}
        Layout={ConnectedSidenav}
        exact={true}
        path={[PATHS.ingredientRequests]}
        secure={true}
        title="Ingredient Requests"
      />
      {/* retailer routes */}
      <View
        Component={RetailerBrandDetailsView}
        Layout={NoSideBarNav}
        exact={true}
        path={[PATHS.retailerBrandDetailsView]}
        roles={[ROLES.retailer]}
        secure={true}
        title="Brand Details"
      />
      <View
        Component={RetailerProductsView}
        Layout={ConnectedSidenav}
        path={[PATHS.retailerProductsView]}
        roles={[ROLES.retailer]}
        secure={true}
        title="Products"
        exact
      />
      <View
        Component={RetailerProductDetailsView}
        Layout={NoSideBarNav}
        path={[PATHS.retailerProductDetailsView]}
        roles={[ROLES.retailer]}
        secure={true}
        title="Product Details"
      />
      <View
        Component={RetailerDocumentsView}
        Layout={ConnectedSidenav}
        exact={true}
        path={[PATHS.retailerDocumentsView]}
        roles={[ROLES.retailer]}
        secure={true}
        title="Documents"
      />
      {/* V2 Retailer Routes */}
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerDashboardView}
        Layout={ConnectedSidenav}
        title="Retailer Dashboard"
        path={[PATHS.retailerDashboard]}
        roles={[ROLES.retailer]}
        secure
      />
      <View
        featureFlags={['sephora_reports_page']}
        Component={RetailerReportsView}
        Layout={ConnectedSidenav}
        title="Retailer Reports"
        path={[PATHS.retailerReports]}
        roles={[ROLES.retailer]}
        secure
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerBrandMetrics}
        Layout={ConnectedSidenav}
        title="Retailer Brand Metrics"
        path={[PATHS.retailerBrandsMetrics]}
        roles={[ROLES.retailer]}
        secure
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerViewBrandSubmissions}
        Layout={ConnectedSidenav}
        title="Retailer Brand Submission Container"
        path={[PATHS.retailerBrandsSubmissions]}
        roles={[ROLES.retailer]}
        secure
      />
      <View
        featureFlags={['retailer_brand_onboarding']}
        Component={RetailerSkuMetrics}
        Layout={ConnectedSidenav}
        title="Retailer SKU Metrics"
        path={[PATHS.retailerSkuMetrics]}
        roles={[ROLES.retailer]}
        secure
      />
      {/* NOT FOUND */}
      <View
        Component={NotFound}
        Layout={NoNav}
        secure={false}
        title="Not Found"
        path="*"
      />
    </Switch>
  );
}
