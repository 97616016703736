import { FC } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';

import {
  Breadcrumbs,
  CloudinaryImg,
  NoContent,
  PhosphorIcon,
  Pill,
  Text,
  TextElement,
  TextKind,
} from 'design-system/components';
import { useApp } from 'context/AppContext';
import { Color, ScreeningStatusPillData } from 'design-system/data';
import {
  convertSnakeToCamelCase,
  applyTwoDigitDateFormat,
} from 'design-system/utils';

import PageHeader from 'views/FDARegistration/shared/components/PageHeader';

import styles from './retailerBrandSubmissions.module.scss';
import AttestationsFeedbackTable from './AttestationsFeedbackTable';
import DocumentsFeedbackTable from './DocumentsFeedbackTable';

const submissionStatusToPill = (status: string) => {
  const statusPillData = ScreeningStatusPillData[status];
  if (!statusPillData) return null;
  return <Pill color={statusPillData.color}>{statusPillData.label}</Pill>;
};

const RetailerBrandSubmissions: FC = () => {
  const { retailerBrand, useApi, user } = useApp();
  const retailer = retailerBrand?.retailer;
  const brandId = user?.organization?.in_orgable_id;

  const { policy_id: policyId } = useParams();
  const url = `/api/v4/retailers/${retailer?.id}/consumer_brands/${brandId}/policies/${policyId}/submission`;

  const { data, loading } = useApi(url, {
    enabled: !!retailer?.id && !!brandId && !!policyId,
  });

  const policyData = convertSnakeToCamelCase(data);
  const { policy, submission } = policyData || {};

  const breadcrumbsItems = [
    {
      text: 'Submissions',
      link: '/retailer-brand/submissions',
    },
    {
      text: policy?.name,
      link: '',
    },
  ];

  return (
    <main>
      <PageHeader
        title={retailerBrand?.retailer?.name}
        subtitle={`View the status and retailer feedback on your submission for the ${policy?.name} program here.`}
        breadcrumbs={<Breadcrumbs items={breadcrumbsItems} />}
      />
      {loading && <NoContent loading />}

      {!loading && (
        <div className={styles['policy-card']}>
          <div className={styles['policy-icon']}>
            <CloudinaryImg
              src={policy?.imagePath}
              alt={policy?.name}
              width={64}
              height={64}
              fallbackElement={
                <PhosphorIcon iconName="ShieldCheck" size={48} />
              }
            />
          </div>
          <div className={cn([styles['policy-content']])}>
            <Text
              kind={TextKind.Display2XSMedium}
              element={TextElement.H2}
              className={styles['title']}
            >
              {submission
                ? `Submitted on ${applyTwoDigitDateFormat(
                    submission.submittedAt
                  )}`
                : 'No current submission'}
              {submissionStatusToPill(submission?.status)}
            </Text>
            {!submission && (
              <Text
                kind={TextKind.TextSM}
                element={TextElement.Span}
                color={Color.Neutral600}
              >
                Some of your SKUs qualify for {policy?.name}. Submit your work
                to {retailerBrand?.retailer?.name} now in order to [tktktktk].
                Once you submit, you will not be able to edit your answers or
                complete more attestations until the retailer has reviewed your
                submission. See your Dashboard for next steps.
              </Text>
            )}
            {submission?.retailerNotes && (
              <div>
                {submission.retailerNotes && (
                  <Text
                    kind={TextKind.TextSMSemibold}
                    element={TextElement.Span}
                    color={Color.Neutral700}
                  >
                    Retailer feedback:&nbsp;
                  </Text>
                )}
                <Text
                  kind={TextKind.TextSM}
                  element={TextElement.Span}
                  color={Color.Neutral600}
                >
                  {submission.retailerNotes}
                </Text>
              </div>
            )}
          </div>
        </div>
      )}

      <DocumentsFeedbackTable
        retailerName={retailer?.name}
        retailerId={retailer?.id}
        brandId={brandId}
        policyId={policyId}
      />
      <AttestationsFeedbackTable
        retailerName={retailer?.name}
        retailerId={retailer?.id}
        brandId={brandId}
        policyId={policyId}
      />
    </main>
  );
};

export default RetailerBrandSubmissions;
