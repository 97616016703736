import { useMemo } from 'react';
import {
  useStatefulQueryParam,
  useNameSpacedQueryParam,
} from 'design-system/utils';

type FilterState = {
  value: string[];
  setValue: (value: string[]) => void;
  paramKey: string;
};

export const useFilterStates = (
  filterOptions: { title: string }[] | null | undefined,
  namespace: string,
  nameSpacedParamsEnabled: boolean
) => {
  // Use string type for query params
  const [regularFilterState, setRegularFilterState] =
    useStatefulQueryParam<string>('filters', '{}', true);
  const [namespacedFilterState, setNamespacedFilterState] =
    useNameSpacedQueryParam<string>('filters', namespace, '{}', true);

  // Select which state to use based on nameSpacedParamsEnabled
  const filterState = nameSpacedParamsEnabled
    ? namespacedFilterState
    : regularFilterState;
  const setFilterState = nameSpacedParamsEnabled
    ? setNamespacedFilterState
    : setRegularFilterState;

  return useMemo(() => {
    if (!filterOptions) return {};

    // Parse the current filter state
    let currentState: Record<string, string[]>;
    try {
      currentState = JSON.parse(filterState);
    } catch {
      currentState = {};
    }

    // Create a mapping of filter states with their setters
    return filterOptions.reduce((acc, group) => {
      const paramKey = group.title.toLowerCase().replace(/\s+/g, '_');

      acc[group.title] = {
        value: currentState[paramKey] || [],
        setValue: (newValue: string[]) => {
          const updatedState = {
            ...currentState,
            [paramKey]: newValue,
          };
          setFilterState(JSON.stringify(updatedState) as '{}');
        },
        paramKey,
      };

      return acc;
    }, {} as Record<string, FilterState>);
  }, [filterOptions, filterState, setFilterState]);
};
