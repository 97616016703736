import { useMemo } from 'react';
import cn from 'classnames';
import {
  Avatar,
  AvatarKind,
  Grid,
  GridItem,
  PhosphorIcon,
  Link,
  LinkKind,
  LinkVariant,
  NoContent,
  NoDataVariants,
  Text,
  TextKind,
  TextElement,
  Progress,
  ProgressSize,
  ProgressVariant,
  Pill,
  PillColor,
} from 'design-system/components';
import { BrandColors } from 'design-system/data';

import Disclaimer from '../shared/Disclaimer';
import magnifyingGlassSvg from 'assets/illustrations/magnifying-glass.svg';
import { PATHS } from 'constants/index';
import links from 'constants/links';
import { useApp } from 'context/AppContext';
import withLineBreaks from 'utils/withLineBreaks';
import { useChargebeeCheckout } from 'utils/chargebee';
import { hasChargebeePaywall } from 'utils/hardcodedNonsense';
import EligibilityProvider from '../../../components/EligibilityStatus/EligibilityContext';
import UltaDashboardEligibility from 'components/EligibilityStatus/UltaDashboardEligibility';
import useRetailerPolicies from '../RetailerBrandWelcome/useRetailerPolicies';
import SubmissionNextSteps from './SubmissionNextSteps/SubmissionNextSteps';
import styles from './retailerBrandDashboard.module.scss';
import NextStepsCTA from './NextStepsCTA/NextStepsCTA';
import {
  DASHBOARD_NEXT_STEPS_CTA_TEXT,
  DASHBOARD_NEXT_STEPS_DEFAULT_TEXT,
  DASHBOARD_NEXT_STEPS_HEADINGS,
} from './NextStepsCTA/constants';
import { useYourProductContext } from '../YourProducts/context';

const hasNonEmptyRequirements = (requirementGroup) =>
  requirementGroup?.totalRequirements !== 0;

const RetailerBrandDashboardContainer = () => {
  const { user, retailerBrand, subscriptionTiers } = useApp();
  const { tableCounts } = useYourProductContext();
  const orgName = user?.organization?.name;
  const hasOnlyUnsignedContracts =
    retailerBrand?.has_only_unsigned_contracts ?? false;
  const {
    data: retailerPolicies,
    error,
    loading,
  } = useRetailerPolicies(retailerBrand?.retailer?.id, retailerBrand?.id);
  const noPolicyData = !retailerPolicies?.policies.length && !loading && !error;
  const noPoliciesAllowSubmission = retailerPolicies?.policies.some(
    (policy) => policy.submissionFlow === 'na'
  );
  const nonEmptyFilteredPolicies = retailerPolicies?.policies.filter((policy) =>
    policy.retailerPolicyRequirementGroups.some(hasNonEmptyRequirements)
  );

  const selectedTier = useMemo(() => {
    if (subscriptionTiers && tableCounts) {
      const subTier = subscriptionTiers.find((sub) => {
        const skuCount = tableCounts.all;
        return (
          sub.product_count_min < skuCount &&
          (sub.product_count_max === null || skuCount < sub.product_count_max)
        );
      });
      return subTier;
    }
    return null;
  }, [subscriptionTiers, tableCounts]);

  const hasUnpaidSubscriptions = !retailerBrand?.unlocked;
  const noUploadedSkus = tableCounts?.all < 1;
  const computedHasChargebeePaywall = hasChargebeePaywall(
    retailerBrand?.retailer?.id,
    user
  );

  const contractsText =
    retailerBrand?.retailer?.custom_pillar_agreement_text &&
    retailerBrand?.retailer?.custom_pillar_agreement_text.length > 0
      ? retailerBrand?.retailer?.custom_pillar_agreement_text
      : DASHBOARD_NEXT_STEPS_DEFAULT_TEXT.signContracts;

  const isUlta = retailerBrand?.retailer?.name?.includes('Ulta Beauty');

  const showNextStepsCTACards =
    isUlta || hasOnlyUnsignedContracts || hasUnpaidSubscriptions;

  const handleChargebeeCheckout = useChargebeeCheckout(retailerBrand?.id);

  return (
    <Grid nestedCols={{ mobile: 4, tablet: 6, desktop: 10 }}>
      <GridItem span={4} responsiveSpan={{ tablet: 4, desktop: 7 }}>
        <div className={styles['four-xl']}>
          <Text kind={TextKind.DisplaySM} element={TextElement.H1}>
            Hi{orgName ? `, ${orgName}` : ''}! Here&apos;s your{' '}
            {retailerBrand?.retailer?.name
              ? `${retailerBrand?.retailer?.name} `
              : ''}
            dashboard.
          </Text>
        </div>
      </GridItem>
      <GridItem span={4} responsiveSpan={{ tablet: 6, desktop: 10 }}>
        {/* per policy next steps if multiple */}
        <div>
          <div className={styles['section-header']}>
            <Text
              kind={TextKind.DisplayXSMedium}
              element={TextElement.P}
              color={BrandColors.Gray900}
            >
              Next Steps
            </Text>
          </div>
          {showNextStepsCTACards && (
            <div className={styles.cards}>
              {hasUnpaidSubscriptions &&
                !noUploadedSkus &&
                (computedHasChargebeePaywall ? (
                  <NextStepsCTA
                    cardHeading={DASHBOARD_NEXT_STEPS_HEADINGS.chargebee}
                    phosphorIconName="HandCoins"
                    descriptiveText={
                      DASHBOARD_NEXT_STEPS_DEFAULT_TEXT.chargebee
                    }
                    redirectPath="#"
                    linkKind={LinkKind.Internal}
                    ctaOnClick={() => handleChargebeeCheckout.call()}
                    ctaButtonText={DASHBOARD_NEXT_STEPS_CTA_TEXT.chargebee}
                    actionLoading={handleChargebeeCheckout.loading}
                    secondaryAction={
                      <Link
                        kind={LinkKind.Internal}
                        variant={LinkVariant.Secondary}
                        to="/retailer-brand/skus"
                      >
                        <span className={styles.cta}>View SKUs</span>
                      </Link>
                    }
                    footerElement={
                      <div className={styles['sku-count']}>
                        <Text
                          kind={TextKind.TextSMSemibold}
                          element={TextElement.P}
                          color={BrandColors.Gray400}
                        >
                          Total SKUs
                        </Text>
                        <Pill color={PillColor.BrandSecondary}>
                          {tableCounts.all || '-'}
                        </Pill>
                        <Pill color={PillColor.BrandSecondary}>
                          {selectedTier?.name || '-'}
                        </Pill>
                      </div>
                    }
                  />
                ) : (
                  <NextStepsCTA
                    cardHeading={DASHBOARD_NEXT_STEPS_HEADINGS.payment}
                    phosphorIconName="HandCoins"
                    descriptiveText={DASHBOARD_NEXT_STEPS_DEFAULT_TEXT.payment}
                    redirectPath="mailto:support@noviconnect.com"
                    linkKind={LinkKind.External}
                    ctaButtonText={DASHBOARD_NEXT_STEPS_CTA_TEXT.payment}
                  />
                ))}
              {hasOnlyUnsignedContracts && (
                <NextStepsCTA
                  cardHeading={DASHBOARD_NEXT_STEPS_HEADINGS.signContracts}
                  phosphorIconName="Signature"
                  descriptiveText={contractsText}
                  redirectPath={PATHS.retailerBrandDocuments}
                  linkKind={LinkKind.Internal}
                  ctaButtonText={DASHBOARD_NEXT_STEPS_CTA_TEXT.signContracts}
                />
              )}
              {noPoliciesAllowSubmission &&
                (!computedHasChargebeePaywall || !hasUnpaidSubscriptions) && (
                  <NextStepsCTA
                    cardHeading={DASHBOARD_NEXT_STEPS_HEADINGS.attestations}
                    phosphorIconName="ListChecks"
                    descriptiveText={
                      DASHBOARD_NEXT_STEPS_DEFAULT_TEXT.attestations
                    }
                    redirectPath={PATHS.retailerBrandRequirementGroups}
                    linkKind={LinkKind.Internal}
                    ctaButtonText={DASHBOARD_NEXT_STEPS_CTA_TEXT.attestations}
                  />
                )}
            </div>
          )}
        </div>
        {(!computedHasChargebeePaywall ||
          !hasUnpaidSubscriptions ||
          noUploadedSkus) && (
          <div className={styles['next-steps-wrapper']}>
            {retailerPolicies?.policies?.length > 0 &&
              retailerPolicies.policies.map((policy) => (
                <SubmissionNextSteps
                  key={policy?.id}
                  policy={policy}
                  loading={loading}
                  retailerName={retailerBrand?.retailer?.name}
                  retailerId={retailerBrand?.retailer?.id}
                  canSubmit={!hasUnpaidSubscriptions}
                  noSkuData={noUploadedSkus}
                />
              ))}
          </div>
        )}
      </GridItem>
      {isUlta && (
        <GridItem span={4} responsiveSpan={{ tablet: 6, desktop: 10 }}>
          <EligibilityProvider>
            <UltaDashboardEligibility />
          </EligibilityProvider>
        </GridItem>
      )}
      {!isUlta && (
        <GridItem span={4} responsiveSpan={{ tablet: 6, desktop: 10 }}>
          <div className={styles['retailer-policy-groups']}>
            {retailerPolicies?.policies &&
              retailerPolicies.policies.length > 0 &&
              nonEmptyFilteredPolicies.map((policy, i) => {
                const hasManyPillars =
                  policy.retailerPolicyRequirementGroups?.length > 1;
                return (
                  <div className={styles.policy} key={i}>
                    <Text
                      kind={TextKind.DisplayXSMedium}
                      element={TextElement.H2}
                    >
                      {policy.name && `${policy.name} `}Pillars
                    </Text>
                    <div className={styles['group-container']}>
                      {policy.retailerPolicyRequirementGroups
                        ?.filter(hasNonEmptyRequirements)
                        .map((requirementGroup, i) => {
                          return (
                            <div
                              className={cn([
                                styles['requirement-group'],
                                !hasManyPillars ? styles.ring : styles.bar,
                              ])}
                              key={i}
                            >
                              <div className={styles['group-content']}>
                                {!hasManyPillars && (
                                  <Progress
                                    variant={ProgressVariant.Ring}
                                    size={ProgressSize.Lg}
                                    percentage={
                                      requirementGroup?.percentComplete
                                    }
                                    label="Complete"
                                  />
                                )}
                                <div>
                                  <div className={styles.title}>
                                    {requirementGroup?.imagePath && (
                                      <Avatar
                                        initials={requirementGroup?.name}
                                        name={requirementGroup?.name}
                                        kind={AvatarKind.Large}
                                        src={requirementGroup?.imagePath}
                                      />
                                    )}
                                    <Text
                                      kind={TextKind.Display2XS}
                                      element={TextElement.H3}
                                    >
                                      {requirementGroup?.name}
                                    </Text>
                                  </div>
                                  <Text
                                    kind={TextKind.TextSM}
                                    element={TextElement.P}
                                  >
                                    {withLineBreaks(
                                      requirementGroup?.description
                                    )}
                                  </Text>
                                </div>
                              </div>
                              <div className={styles['percent-review-block']}>
                                {hasManyPillars && (
                                  <Progress
                                    variant={ProgressVariant.Bar}
                                    percentage={
                                      requirementGroup?.percentComplete
                                    }
                                  />
                                )}
                                <Link
                                  kind={LinkKind.Internal}
                                  variant={LinkVariant.NewDefault}
                                  to={`requirement_groups/${requirementGroup?.id}/retailer_requirements`}
                                >
                                  Review
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            {loading && <NoContent loading />}
            {noPolicyData && (
              <NoContent variant={NoDataVariants.Default}>
                {user.hasFF('upload_skus') && (
                  <Link
                    kind={LinkKind.Internal}
                    variant={LinkVariant.Alternate}
                    to={PATHS.retailerBrandProducts}
                  >
                    Upload your SKUs <PhosphorIcon iconName="ArrowRight" />
                  </Link>
                )}
              </NoContent>
            )}
          </div>
        </GridItem>
      )}
      <GridItem span={4} responsiveSpan={{ tablet: 6, desktop: 10 }}>
        <div className={styles['dash-footer']}>
          <div className={cn([styles.resources, styles.panel])}>
            <img
              src={magnifyingGlassSvg}
              alt="magnifying glass"
              onError={() => <span className={styles.fallback} />}
            />
            <div>
              <div className={styles.xl}>
                <Text kind={TextKind.Display2XSMedium} element={TextElement.H3}>
                  Can&apos;t find what you&apos;re looking for?
                </Text>
              </div>
              <div className={styles['three-xl']}>
                <Link
                  kind={LinkKind.External}
                  variant={LinkVariant.NewDefault}
                  href={
                    retailerBrand?.retailer?.support_url ||
                    'https://knowledgebase.noviconnect.com/'
                  }
                  target="_blank"
                >
                  Visit the resource hub
                </Link>
              </div>
              <Link
                variant={LinkVariant.Primary}
                kind={LinkKind.External}
                href={retailerBrand?.retailer?.inbox_url || links.mailtoSupport}
              >
                Contact Novi Support
              </Link>
            </div>
          </div>
          <div className={cn([styles.explore, styles.panel])}>
            <div className={styles['three-xl']}>
              <Text kind={TextKind.Display2XSMedium} element={TextElement.H3}>
                Explore more on Novi
              </Text>
            </div>
            <div className={styles.md}>
              <Link
                kind={LinkKind.Internal}
                variant={LinkVariant.NewDefault}
                to="/brand/INCI-screening"
              >
                Test compliance against other industry standards
              </Link>
            </div>
            <div className={styles.md}>
              <Link
                kind={LinkKind.Internal}
                variant={LinkVariant.NewDefault}
                to="/brand/discover?category_key=ingredient"
              >
                Discover and source more sustainable ingredients
              </Link>
            </div>
          </div>
        </div>
      </GridItem>
      {retailerBrand?.retailer?.disclaimer && (
        <Disclaimer text={retailerBrand.retailer.disclaimer || ''} />
      )}
    </Grid>
  );
};

export default RetailerBrandDashboardContainer;
