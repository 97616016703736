import { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  NoContent,
  NoDataVariants,
  PaginationV2,
  PillColor,
  PillColorNames,
  SearchFilter,
  Tab,
  TabGroup,
  Table,
  TableBody,
  TableBodyCell,
  TableFoot,
  TableHead,
  TableHeadCell,
  TableRow,
  TabsAndTable,
} from 'design-system/components';

import { RequirementResult } from 'design-system/types';

import AttestationsFeedbackRow from './TableRow';
import styles from '../retailerBrandSubmissions.module.scss';
import { SORT_OPTIONS } from '../constants';
import { EndpointResponse, TableProps } from './types';
import { useNameSpacedQueryParam } from 'design-system/utils';
import { UrlParam } from 'design-system/data';

const AttestationsFeedbackTable = ({
  retailerName,
  setSelectedRequirement,
  activeTabIndex,
  setActiveTabIndex,
  setModalOpen,
  modalOpen,
  setCurrentRow,
  modalNavPrevLogic,
  modalNavNextLogic,
  tableData,
}: TableProps) => {
  const { data, loading, error }: EndpointResponse = tableData;
  const results = data?.results;
  const noContent = !data?.results.length && !loading && !error;
  const namespace = 'attestations';

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [filterState, setFilterState] = useState<string>(
    JSON.stringify(params.get(`${namespace}-filters`))
  );
  const [searchState, setSearchState] = useState<string>(
    JSON.stringify(params.get(`${namespace}-search`))
  );

  const [currentPage, setCurrentPage] = useNameSpacedQueryParam(
    UrlParam.Page,
    namespace,
    1
  );

  useEffect(() => {
    const newValue = JSON.stringify(params.get(`${namespace}-filters`));
    if (filterState !== newValue) {
      setFilterState(newValue);
      if (currentPage !== 1) setCurrentPage(1);
    }
  }, [params, currentPage]);

  useEffect(() => {
    const newValue = JSON.stringify(params.get(`${namespace}-search`));
    if (searchState !== newValue) {
      setSearchState(newValue);
      if (currentPage !== 1) setCurrentPage(1);
    }
  }, [params, currentPage]);

  const [, setStatusFilter] = useNameSpacedQueryParam<string | null>(
    'pillar_status',
    namespace,
    ''
  );

  const columns: { label: string; value: string }[] = [
    {
      label: 'Requirement',
      value: 'requirement',
    },
    {
      label: 'Pillar',
      value: 'pillar',
    },
    {
      label: 'SKU',
      value: 'sku_name',
    },
    {
      label: 'Status',
      value: 'requirement_status',
    },
    {
      label: '',
      value: 'cta',
    },
  ];

  const tabData = useMemo(() => {
    const allData = {
      id: 0,
      label: 'All requirements',
      value: 'all-requirements',
      quantity:
        (data?.screeningStatusCounts?.pass ?? 0) +
        (data?.screeningStatusCounts?.fail ?? 0) +
        (data?.screeningStatusCounts?.rejected ?? 0) +
        (data?.screeningStatusCounts?.pending ?? 0),
      pillColor: PillColor.Purple as PillColorNames,
    };
    const meetsData = {
      id: 1,
      label: 'Accepted',
      value: 'pass',
      quantity: data?.screeningStatusCounts?.pass ?? 0,
      pillColor: PillColor.Green as PillColorNames,
    };
    const doesNotMeetData = {
      id: 2,
      label: 'Rejected',
      value: 'rejected',
      quantity:
        (data?.screeningStatusCounts?.fail ?? 0) +
        (data?.screeningStatusCounts?.rejected ?? 0),
      pillColor: PillColor.Red as PillColorNames,
    };
    const pendingData = {
      id: 3,
      label: 'Pending',
      value: 'pending',
      quantity: data?.screeningStatusCounts?.pending ?? 0,
      pillColor: PillColor.Blue as PillColorNames,
    };

    const brandTabData = [allData, meetsData, doesNotMeetData, pendingData];

    return brandTabData;
  }, [data?.screeningStatusCounts]);

  const builtFilterOptions = useMemo(() => {
    if (!data?.pillars?.length) return null;

    return [
      {
        title: 'Pillar',
        options: data?.pillars?.map(
          (pillar) =>
            ({
              label: pillar.name,
              value: pillar.id.toString(),
            } ?? [])
        ),
      },
    ];
  }, [data?.pillars]);

  return (
    <TabsAndTable
      tabs={
        <TabGroup
          activeTabIndex={activeTabIndex}
          ariaLabelledBy="tabs-and-filter-header-subtitle"
          additionalHeader={
            <SearchFilter
              sortOptions={SORT_OPTIONS}
              filterOptions={builtFilterOptions}
              namespace={namespace}
            />
          }
        >
          {tabData.map((tab, i) => (
            <Tab
              key={tab.id}
              chipValue={tab.quantity}
              onClick={() => {
                setActiveTabIndex(i);
                setStatusFilter(
                  tab.value === 'all-requirements' ? null : tab.value || ''
                );
              }}
              isActive={activeTabIndex === i}
              pillColor={tab.pillColor}
            >
              {tab.label}
            </Tab>
          ))}
        </TabGroup>
      }
      table={
        <>
          {(loading || error) && (
            <NoContent
              variant={NoDataVariants.Default}
              loading={!error && loading}
            >
              {error && (
                <>
                  An error occurred while fetching the data. Please try again
                  later or reach out to support.
                </>
              )}
            </NoContent>
          )}
          {noContent && (
            <NoContent variant={NoDataVariants.Default}>
              Submit your attestations to {retailerName} to receive feedback.
            </NoContent>
          )}
          {!loading && !error && !noContent && (
            <div className={styles.table}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHeadCell key={column.label}>
                        {column.label}
                      </TableHeadCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results?.map((result: RequirementResult, i) => (
                    <AttestationsFeedbackRow
                      key={`${result.requirementId}-${i}`}
                      result={result}
                      modalOpen={modalOpen}
                      onClick={() => {
                        setSelectedRequirement(result);
                        setModalOpen(true);
                        setCurrentRow(i + 1);
                        modalNavPrevLogic(i);
                        modalNavNextLogic(i);
                      }}
                      isActive={false}
                    />
                  ))}
                </TableBody>
                <TableFoot bgWhite>
                  <TableRow>
                    <TableBodyCell colSpan={3}>
                      <PaginationV2
                        onNextPage={() =>
                          setCurrentPage((currentPage + 1) as 1)
                        }
                        onPreviousPage={() =>
                          setCurrentPage((currentPage - 1) as 1)
                        }
                        onPageSelect={setCurrentPage}
                        currentPage={data?.page}
                        totalItemCount={data?.count}
                        itemsPerPage={data?.items}
                      />
                    </TableBodyCell>
                  </TableRow>
                </TableFoot>
              </Table>
            </div>
          )}
        </>
      }
    />
  );
};

export default AttestationsFeedbackTable;
