import useApi from 'services/api/useApi';
import { EndpointResponseRaw } from './types';
import { useNameSpacedQueryParams } from 'design-system/utils';
import { transformFiltersToParams } from '../common/util';

export const useDocumentsFeedbackData = (
  retailerId: string,
  brandId: string,
  policyId: string
): EndpointResponseRaw => {
  const [params] = useNameSpacedQueryParams('documents');
  const url = `/api/v4/retailers/${retailerId}/consumer_brands/${brandId}/policies/${policyId}/document_approvals`;

  // Transform the JSON filters into flat URL parameters
  const transformedParams = transformFiltersToParams(params);

  const { data, loading, error } = useApi(url, {
    param: transformedParams,
    enabled: Boolean(retailerId && brandId && policyId),
  });

  return {
    data,
    loading,
    error,
  } as EndpointResponseRaw;
};
