import { useEffect } from 'react';
import { Modal, ModalType } from 'design-system/components';
import { ChargebeeType } from 'design-system/types';

import { useApp } from 'context/AppContext';
import styles from './chargebee-modal.module.scss';

// We ultimately don't own the iframe content, so if chargebee changes..
// .. our implementation can become out of sync.
const ChargebeeModal = () => {
  const {
    chargebeeData,
    setChargebeeData,
    refetchRetailerBrand,
    selectedRetailerBrandId,
  } = useApp();

  // Scroll iframe to top on load without making cors sad.
  useEffect(() => {
    if (chargebeeData?.url) {
      const modal = document.getElementById('chargebee-modal');
      const modalContent = modal?.querySelector('[data-cy="modal-content"]');
      const iframe = document.getElementById('chargebee-iframe');
      iframe?.addEventListener('load', () => {
        if (modalContent) {
          // use hardcoded value b/c measured distance is variable with state.
          modalContent.scrollTop = 158;
        }
      });
    }
  }, [chargebeeData?.url]);

  return (
    <Modal
      show={Boolean(chargebeeData?.url)}
      onClick={() => {
        if (chargebeeData?.type === ChargebeeType.CHECKOUT) {
          refetchRetailerBrand(selectedRetailerBrandId);
        }
        setChargebeeData(undefined);
      }}
      type={ModalType.ExtraLarge}
      confirmClose
      confirmCloseLabel={chargebeeData?.exitLabel}
      confirmCloseDescription={chargebeeData?.exitDescription}
      id="chargebee-modal"
    >
      <div className={styles.container} id="chargebee-iframe-container">
        <iframe
          id="chargebee-iframe"
          src={chargebeeData?.url}
          title="Chargebee Modal"
          allow="payment"
          loading="lazy"
        />
      </div>
    </Modal>
  );
};

export default ChargebeeModal;
